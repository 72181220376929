// import { AuthResponse, mockManagerDataAuth, mockTesterDataAuth, SignIn } from '../types/auth';
import * as http from "../utils/http";
import delve from "dlv";
import { IPaging } from "./interface/common";
// import queryString from "query-string";

const createUserUrl = "/api/auth/signup/";
const updateUserByIdUrl = "/api/account/updateProfile/";
const createCompnayUrl = "/api/companies/createCompany";
const checkEmailUrl = "/api/account/checkEmail/";
const checkUsernameUrl = "/api/account/checkUsername/";

const companyUrl = "/api/companies/";

export interface ISearchContent {
  page: number;
  limit: number;
  searchText: string;
}

export interface ISearchUser {
  page: number;
  limit: number;
  searchText: string;
  companyId: string;
}

export interface IUser {
  id?: string;
  isDelete?: boolean;
  isActive?: boolean;
  isNotification?: boolean;
  isChangePass?: boolean;
  isDisableEmail?: boolean;
  company?: any;
  email: string;
  username: string;
  password?: string;
  firstName: string;
  lastName: string;
  roles: string[];
  keyboard: string;
  language?: string;
  countryCode?: string;
  location?: string;
  categories?: string[];
}

export function toIUser(pra: IUser): IUser {
  return {
    id: pra.id,
    email: pra.email,
    username: pra.username,
    firstName: pra.firstName,
    lastName: pra.lastName,
    roles: pra.roles,
    keyboard: pra.keyboard,
    language: pra.language,
    isDelete: pra.isDelete,
    isActive: pra.isActive,
    isNotification: pra.isNotification,
    isDisableEmail: pra.isDisableEmail,
    isChangePass: pra.isChangePass,
    company: pra.company,
    categories: pra.categories,
    location: pra.location,
  };
}

export interface ICompany {
  id?: string;
  email: string;
  name: string;
  description: string;
  phone: string;
  adress: string;
  keyboards?: any;
  languages?: any;
  isJobRun?: boolean;
  isDelete?: boolean;
  isActive?: boolean;
  categoryTarget?: any;
}

export function toICompany(pra: ICompany): ICompany {
  return {
    id: pra.id,
    email: pra.email,
    name: pra.name,
    description: pra.description,
    phone: pra.phone,
    adress: pra.adress,
    keyboards: pra.keyboards,
    languages: pra.languages,
    isJobRun: pra.isJobRun,
    isDelete: pra.isDelete,
    isActive: pra.isActive,
    categoryTarget: pra.categoryTarget,
  };
}

export const createCompnay = async (company: any) => {
  return http
    .fetchWithAuth<any, { data: any }>(`${createCompnayUrl}`, {
      method: "post",
      data: company,
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const disableCompanyJob = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/companies/updateAutoDownloadImage/${id}`,
      {
        method: "put",
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const disableCompany = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/companies/disableCompany/${id}`, {
      method: "put",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const deleteCompany = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/companies/deleteCompany/${id}`, {
      method: "put",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const createUser = async (user: IUser, companyId: string) => {
  const url = user.id ? `${updateUserByIdUrl}${user.id}` : createUserUrl;
  return http
    .fetchWithAuth<any, { data: any }>(`${url}`, {
      method: "POST",
      data: { ...user, companyId },
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const disableUser = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/account/disableAccount/${id}`, {
      method: "put",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const deteleteUser = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/account/deleteAccount/${id}`, {
      method: "put",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const resetPassword = async (
  password: string,
  rePassword: string,
  userId: string
): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/account/resetPassword/`, {
      method: "post",
      data: { password, rePassword, userId },
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const updateKeyboard = async (keyboard: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/users/updateKeyboard/${keyboard}`,
      {
        method: "put",
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const getUserById = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/users/${id}`, {
      method: "get",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const updateLanguage = async (language: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/users/updateLanguage/${language}`,
      {
        method: "put",
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const updateNotificationStatus = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/account/disableSendEmail/${id}`, {
      method: "put",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const updateRegion = async (countryCode: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/users/updateRegion/${countryCode}`,
      {
        method: "put",
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const getPractiseInactiveByUserId = async (
  userId: string
): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/users/getPractiseInactive/${userId}`,
      {
        method: "get",
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const inactivePractise = async (
  practiseId: number,
  companyId: string,
  userId: string
): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/users/inactivePractise/${practiseId}/${companyId}/${userId}`,
      {
        method: "put",
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const isEmailExist = async (
  email: string,
  id: string
): Promise<Boolean> => {
  return http
    .fetchWithAuth<any, { data: any }>(`${checkEmailUrl}${email}/${id}`, {
      method: "get",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const isUsernameExist = async (
  username: string,
  id: string
): Promise<Boolean> => {
  return http
    .fetchWithAuth<any, { data: any }>(`${checkUsernameUrl}${username}/${id}`, {
      method: "get",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};

export const searchUserService = async (
  searchContent: ISearchUser
): Promise<IPaging<IUser>> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/account/getAll?searchText=${searchContent.searchText}&page=${searchContent.page}&limit=${searchContent.limit}&companyId=${searchContent.companyId}`,
      {
        method: "get",
      }
    )
    .then((data: any) => {
      const datas = delve(data, "datas");
      if (datas) {
        return {
          datas: data.datas.map((ma: any) => toIUser(ma)),
          total: data.total,
        };
      } else {
        return {
          datas: [],
          total: 0,
        };
      }
    })
    .catch((error) => {
      return error;
    });
};

export const searchUserAutocomplete = async (
  searchText: string,
  companyId: string
): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `/api/account/getAll?searchText=${searchText}&page=1&limit=10&companyId=${companyId}`,
      {
        method: "get",
      }
    )
    .then((data: any) => {
      const datas = delve(data, "datas");
      if (datas) {
        return {
          datas: data.datas.map((ma: any) => {
            return {
              id: ma.id,
              email: ma.email,
              username: ma.username,
              firstName: ma.firstName,
              lastName: ma.lastName,
            };
          }),
        };
      } else {
        return {
          datas: [],
          total: 0,
        };
      }
    })
    .catch((error) => {
      return error;
    });
};

export const searchCompanyService = async (
  searchContent: ISearchContent
): Promise<IPaging<ICompany>> => {
  return http
    .fetchWithAuth<any, { data: any }>(
      `${companyUrl}getAll?searchText=${searchContent.searchText}&page=${searchContent.page}&limit=${searchContent.limit}`,
      {
        method: "get",
      }
    )
    .then((data: any) => {
      const datas = delve(data, "datas");
      if (datas) {
        return {
          datas: data.datas.map((ma: any) => toICompany(ma)),
          total: data.total,
        };
      } else {
        return {
          datas: [],
          total: 0,
        };
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getCompanyByIdService = async (id: string): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`${companyUrl}detail/${id}`, {
      method: "get",
    })
    .then((data: any) => {
      if (data) {
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      return error;
    });
};
